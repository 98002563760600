import { Injectable, OnDestroy } from "@angular/core";
import { JsonrpcService } from "./jsonrpc.service";
import * as _ from "underscore";
import { GlobalService } from "./global.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ConfigurationLogicService } from "./configuration-logic.service";
import { lastValueFrom, Subscription } from "rxjs";
import { FunctionsService } from "@app/_services/functions.service";
import { CONSTANTS } from "@app/util/constants";
import { SubcollectionFileModel } from "../models/subcollectionFileModel";
import { UploadService } from "@app/_services/upload.service";
import { environment } from "@environments/environment";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { TranslationService } from "./translation.service";
import { NotificationsService } from "./notifications.service";
import { OrderRejectionMail } from "@app/interfaces/order-rejection-mail.interface";
import { evaluate, parseMixedVal } from "@app/util/helper";
import { BlurOptions } from "@app/models/blur-options-list";
import { FileUploadListTargetEnum } from "@app/models/file-upload-list-target.enum";
import { EsoftIMGOrderMapElement } from "@app/interfaces/esoft-img-order-map-element.interface";
import { BackboneConfig } from "@app/interfaces/backbone-config.interface";
import { ComparatorEnum } from "@app/models/comparator.enum";

@Injectable({
  providedIn: "root",
})
export class EsoftApiService implements OnDestroy {
  private defaultHeaders; // default headers for api call to esoft
  public esoftEnvConfig; // Esoft realted configuration from environment.ts
  public esoftDBConfig: any; // esoft related configuration saved in database
  public $esoftDBConfigSub: any; // subscription reference for esoftDBConfig
  public subscriptions: Subscription[] = []; // to hold the subscriptions so that that can be unsubscribed all together
  private backboneConfig: BackboneConfig;
  protected esoftIMGOrderMap: EsoftIMGOrderMapElement[] = [
    {
      // picture editing without logo
      packageNums: [
        CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_FOTOS, // Bildbearbeitung - Drohnenaufnahmen - Foto
        CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_REALESTATE, // Bildbearbeitung - Innenfotos (hdphotos)
        CONSTANTS.PACKAGE_KEYS.RETOUCHING_BASIC, // Bildbearbeitung - Fotonachbearbeitung
      ],
      productId: 50150001,
      variant1: "IMG_PS",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "logo",
          operator: ComparatorEnum.Equal,
          value: false,
        },
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.Retouching,
        },
        {
          property: "assignedTo",
          operator: ComparatorEnum.NotIn,
          value: ["6loLqsAwx3YkMLcHLyN5IGZ5yuw1", "zF1dfXHqo5aWNP5HIcyyEl9Y7ZG3"],
        },
      ],
    },
    {
      // picture editing with logo
      packageNums: [
        CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_FOTOS, // Bildbearbeitung - Drohnenaufnahmen - Foto
        CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_REALESTATE, // Bildbearbeitung - Innenfotos
        CONSTANTS.PACKAGE_KEYS.RETOUCHING_BASIC, // Bildbearbeitung - Fotonachbearbeitung
      ],
      productId: 50150001,
      variant1: "IMG_PSL",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "logo",
          operator: ComparatorEnum.Equal,
          value: true,
        },
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.Retouching,
        },
        {
          property: "assignedTo",
          operator: ComparatorEnum.NotIn,
          value: ["6loLqsAwx3YkMLcHLyN5IGZ5yuw1", "zF1dfXHqo5aWNP5HIcyyEl9Y7ZG3"],
        },
      ],
    },
    // picture editing HDR
    {
      packageNums: [CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_FOTOS, CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_REALESTATE],
      productId: 50150002,
      variant1: "IMG_PIH",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.Retouching,
        },
        {
          property: "assignedTo",
          operator: ComparatorEnum.OneOf,
          value: ["6loLqsAwx3YkMLcHLyN5IGZ5yuw1", "zF1dfXHqo5aWNP5HIcyyEl9Y7ZG3"],
        },
      ],
    },
    {
      // Retouching of pictures
      packageNums: [CONSTANTS.PACKAGE_KEYS.RETOUCHING_STANDARD, CONSTANTS.PACKAGE_KEYS.RETOUCHING_PREMIUM],
      productId: 50200000,
      variant1: "IMG_RSYM",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.Retouching,
        },
      ],
    },
    {
      // Retouching of video without blurring
      packageNums: [
        CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_VIDEO, // Bildbearbeitung - Drohnenaufnahmen - Video
      ],
      productId: 50450001, // Video Standard / IMG - "Without" blurring
      variant1: "IMG_VS",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.Retouching,
        },
        {
          property: "blurred",
          operator: ComparatorEnum.Equal,
          value: false,
        },
      ],
    },
    {
      // Retouching of video with blurring
      packageNums: [
        CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_VIDEO, // Bildbearbeitung - Drohnenaufnahmen - Video
      ],
      orderTypes: [OrderTypeEnum.Retouching],
      productId: 50450002, // Video Standard / IMG - "With" blurring
      variant1: "IMG_VI",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.Retouching,
        },
        {
          property: "blurred",
          operator: ComparatorEnum.Equal,
          value: true,
        },
      ],
    },
    {
      // virtual staging
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_STILL_IMAGE, CONSTANTS.PACKAGE_KEYS.VSTAGING_360_IMAGE],
      productId: 50550005,
      variant1: "IMG_FRST",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "virtualTour",
          operator: ComparatorEnum.Equal,
          value: false,
        },
        {
          property: "renovateRooms",
          operator: ComparatorEnum.Equal,
          value: false,
        },
      ],
    },
    {
      // virtual staging
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_STILL_IMAGE, CONSTANTS.PACKAGE_KEYS.VSTAGING_360_IMAGE],
      productId: 50550005,
      variant1: "IMG_FRR",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "virtualTour",
          operator: ComparatorEnum.Equal,
          value: false,
        },
        {
          property: "renovateRooms",
          operator: ComparatorEnum.Equal,
          value: true,
        },
      ],
    },
    {
      // Digitales Staging - 360° Bilder with Virtual tour
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_360_IMAGE],
      productId: 50550000,
      variant1: "IMG_FSYMP",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "virtualTour",
          operator: ComparatorEnum.Equal,
          value: true,
        },
        {
          property: "indvVirtualTour",
          operator: ComparatorEnum.Equal,
          value: false,
        },
      ],
    },
    {
      // Digitales Staging - 360° Bilder with individual Virtual tour
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_360_IMAGE],
      productId: 50550000,
      variant1: "IMG_FSYMPE",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "indvVirtualTour",
          operator: ComparatorEnum.Equal,
          value: true,
        },
      ],
    },
    {
      // Digitales Staging - Office Staging - Standilder without Renovation
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_OFFICE_STANDBILD],
      orderTypes: [OrderTypeEnum.V_Staging],
      productId: 50550002,
      variant1: "IMG_FC",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "renovateRooms",
          operator: ComparatorEnum.Equal,
          value: false,
        },
      ],
    },
    {
      // Digitales Staging - Office Staging - Standilder with Renovation
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_OFFICE_STANDBILD],
      productId: 50550002,
      variant1: "IMG_FCR",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "renovateRooms",
          operator: ComparatorEnum.Equal,
          value: true,
        },
      ],
    },
    {
      // Digitales Staging - Office Staging - 360° Bilder without Renovation
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_OFFICE_360_BILDER],
      productId: 50550002,
      variant1: "IMG_FCP",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "renovateRooms",
          operator: ComparatorEnum.Equal,
          value: false,
        },
      ],
    },
    {
      // Digitales Staging - Office Staging - 360° Bilder with Renovation
      packageNums: [CONSTANTS.PACKAGE_KEYS.VSTAGING_OFFICE_360_BILDER],
      productId: 50550002,
      variant1: "IMG_FCPR",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.V_Staging,
        },
        {
          property: "renovateRooms",
          operator: ComparatorEnum.Equal,
          value: true,
        },
      ],
    },
    {
      // Virtual Tour Immoviewer
      packageNums: [CONSTANTS.PACKAGE_KEYS.VIRTUAL_TOUR_IMMOVIEWER],
      productId: 50250003,
      variant1: "IMG_7SV",
      unit: "Pcs",
      requiredProperties: [
        {
          property: "orderType",
          operator: ComparatorEnum.Equal,
          value: OrderTypeEnum.Retouching,
        },
      ],
    },
  ];
  // All the package numbers for the video packages goes here
  protected videoPackages = ["1"];
  // Comments to be added on virtual staging order
  protected vstagingComments: any = {
    productType: "Type of Product: ",
    reference: "Product Ref: ",
    numberOfPhotos: "Quantity: ",
    renovateRooms: "Renovation: ",
    virtualTour: "Virtual Tour: ",
    floorplan: "Floorplan in Tour: ",
    styleName: "Style: ",
    removeFurniture: "Remove existing Furniture (also cables from the ceiling): ",
    numberOfWalls: "Move walls (changes noted in floor plan): ",
    accessories: "Amount of accessories: ",
    accessoriesDesc:
      'Please see the attached file named {{filename}} for details. The left column shows "Clean" which means less amount of accessories. The right column shows "Comfortable" which means more amount of accessories.',
  };
  private videoTourLogoComment = {
    logoAtBeginning: "Logo at the beginning of the video: ",
    logoAtEnd: "Logo at the end of the video: ",
    logoAllTheTime: "Logo at all the time: ",
  };
  protected logoComment = "Logo: ";
  protected logoPositionComment = "Logo-Position: ";
  protected vstagingFloorplanText =
    "Yes (Floor plan is refurbished by us and sent to you within the next two days via Email.";
  protected vstagingStyleMap = {
    Skandinavisch: {
      styleName: "Skandinavisch",
      fileName: "style_skandinavisch.pdf",
    },
    Modern: {
      styleName: "Modern",
      fileName: "style_modern.pdf",
    },
    Luxuriös: {
      styleName: "Luxurious",
      fileName: "style_luxurious.pdf",
    },
    Industrial: {
      styleName: "Industrial",
      fileName: "style_industrial.pdf",
    },
    Basic: {
      styleName: "Basic",
      fileName: "style_basic.pdf",
    },
  };
  protected vstagingAccessoriesCommentMap = {
    "Wenige Accessoires": "Clean",
    "Viele Accessoires": "Comfortable",
  };
  protected customerComment = "Comment from the customer: ";
  protected imogentComment = "Comment: ";
  protected blurredComment = "Blurring: Yes (follow blurring guideline)";
  protected notBlurredComment = "Blurring: Partially (don’t blur neighbouring houses)";

  protected retouchingComments: any = {
    // packageNum : Comment
    "7": ["We approve retouching Basic, Standard, and Intermediate for this order, whichever is applicable."],
    "25": ["We approve retouching Advanced and Extreme for this order, whichever is applicable."],
  };
  private blueSkyComment = "Blue sky: Yes";
  private notBlueSkyComment = "Blue sky: No";
  private grasComment = "Green Grass: Yes";
  private notGrasComment = "Green Grass: No";

  private readonly subordersEndpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.SUBORDERS;
  private readonly feedbacksEndpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.FEEDBACKS;

  constructor(
    protected jsonRpc: JsonrpcService,
    protected gs: GlobalService,
    protected conf: ConfigurationLogicService,
    private uploadService: UploadService,
    private fs: FunctionsService,
    private http: HttpClient,
    private uls: UsersLogicService,
    private ts: TranslationService,
    private ns: NotificationsService
  ) {
    // initialize configuration variable
    this.esoftEnvConfig = this.gs.getEnvironmentConfig("esoft");
    // initialize default headers
    this.defaultHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `EWS ${this.esoftEnvConfig.apiUser}:${btoa(this.esoftEnvConfig.apiPassword)}`,
    });
    this.conf.getConfiguration<BackboneConfig>(CONSTANTS.CONFIGURATIONS.BACKBONE).subscribe((data) => {
      this.backboneConfig = data as BackboneConfig;
    });
  }

  getEsoftArticle(suborder: SubOrder) {
    const originalSuborderPackageKey = parseMixedVal(suborder.packageNum);

    return this.esoftIMGOrderMap.find((option) => {
      return (
        option.packageNums.includes(originalSuborderPackageKey) &&
        option.requiredProperties?.every((criterion) => {
          if (suborder.hasOwnProperty(criterion.property)) {
            if (evaluate(suborder[criterion.property as keyof SubOrder], criterion.value, criterion.operator)) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
      );
    });
  }

  /**
   * Set a header attribute for api call
   *
   * @param {string} name
   * @param {string} value
   * @memberof EsoftApiService
   */
  // setHeader(name: string, value: string) {
  //   this.headers.set(name, value);
  // }

  /**
   * Set default options for the api call
   *
   * @param {{}} options
   * @memberof EsoftApiService
   */
  getDefaultOptions() {
    return { headers: this.defaultHeaders };
  }

  /**
   * Prepare request body for the api call
   *
   * @param {{}} subOrder
   * @memberof EsoftApiService
   */
  async prepareRequestBody(method: string, subOrder: any, esoftResponse?: any) {
    const subOrderUser = await this.uls.getUserDetails(subOrder.createdBy).toPromise();

    let requestBody: any = {
      id: subOrder.id,
      method: method,
      params: {
        clientId: this.esoftEnvConfig.clientId,
      },
    };
    let esoftProduct: any = {};
    let materials: any = [];
    let quantity = 0;
    let isAssignedToBackbone = false; // To keep it simple, backbone special logic is hardcoded for now.
    const backboneConfig =
      this.conf.backboneConfig ||
      ((await lastValueFrom(this.conf.getConfiguration(CONSTANTS.CONFIGURATIONS.BACKBONE))) as BackboneConfig);

    esoftProduct = this.getEsoftProductDetails(subOrder);
    if (_.isEmpty(esoftProduct)) {
      console.log("No related esoft product found");
      return false;
    }

    switch (method) {
      case "createOrder":
        let comments = [];
        // add comments by the customer
        if (_.has(subOrder, "retouchingComment") && !_.isEmpty(subOrder.retouchingComment)) {
          const customerComments = await this.ts.translate(subOrder.retouchingComment);
          comments.push(this.customerComment + customerComments);
        }
        // add comment for blurring
        if (subOrder.blurred) {
          comments.push(this.blurredComment);
        }
        // add comment for not blurring
        if (subOrder.blurred === false) {
          comments.push(this.notBlurredComment);
        }

        // add comment for required aspect ratio if present
        if (subOrder.photoFormat) {
          comments.push("Please crop the images to the following format: " + subOrder.photoFormat);
        }
        // add comment for blue sky for retouching
        if (
          subOrder.blueSky ||
          subOrder.packageName === CONSTANTS.PACKAGE_NAMES[OrderTypeEnum.Virtual_Tour].IMMOVIEWER_RETOUCHING
        ) {
          comments.push(this.blueSkyComment);
        }
        if (subOrder.blueSky === false) {
          comments.push(this.notBlueSkyComment);
        }

        if (subOrder.gras === true) {
          comments.push(this.grasComment);
        }
        if (subOrder.gras === false) {
          comments.push(this.notGrasComment);
        }

        if (subOrder.blurOptions === BlurOptions.PhotosDocumentsPlates) {
          comments.push("Blurring: Partially (don’t blur neighbouring houses)");
        }
        if (subOrder.blurOptions === BlurOptions.SurroundingHouses) {
          comments.push("Blurring: Yes (follow blurring guideline)");
        }

        // add pacakge specific addtional comments
        switch (subOrder.packageNum) {
          // Virtual staging orders
          case "8":
            comments.push(this.vstagingComments.productType + "Still Image");
            break;
          case "9":
            comments.push(this.vstagingComments.productType + "360°-Images");
            break;
          // Retouching orders
          case "7":
            comments = comments.concat(this.retouchingComments["7"]);
            break;
          case "25":
            comments = comments.concat(this.retouchingComments["25"]);
            break;
        }
        if (_.has(subOrder, "renovateRooms")) {
          comments.push(this.vstagingComments.renovateRooms + (subOrder.renovateRooms ? "Yes" : "No"));
        }
        if (_.has(subOrder, "virtualTour")) {
          comments.push(this.vstagingComments.virtualTour + (subOrder.virtualTour ? "Yes" : "No"));
        }
        if (_.has(subOrder, "withFloorplan")) {
          comments.push(this.vstagingComments.floorplan + (subOrder.withFloorplan ? this.vstagingFloorplanText : "No"));
        }
        if (_.has(subOrder, "logo")) {
          if (!CONSTANTS.ESOFT.LOGO_SEPARATE_PRODUCT_PACKAGE_NUMS.includes(parseMixedVal(subOrder.packageNum))) {
            // In case we use a different product when the logo option is selected, don't add the logo comment.
            comments.push(this.logoComment + (subOrder.logo ? "Yes" : "No"));
          }

          // Retouching orders from FALC users need extra info for the logo position
          if (
            subOrder.orderType === OrderTypeEnum.Retouching &&
            subOrderUser.email.endsWith("@falcimmo.de") &&
            subOrder.logo
          ) {
            comments.push(this.logoPositionComment + "Please place the logo at the bottom left");
          }
        }
        if (_.has(subOrder, "styleName") && _.has(this.vstagingStyleMap, subOrder.styleName)) {
          comments.push(this.vstagingComments.styleName + this.vstagingStyleMap[subOrder.styleName].styleName);
        }
        // Virtual staging accessories comment
        if (_.has(subOrder, "accessoires") && this.vstagingAccessoriesCommentMap[subOrder.accessoires]) {
          let accessoriesComment = this.vstagingComments.accessories;
          accessoriesComment += this.vstagingAccessoriesCommentMap[subOrder.accessoires];
          comments.push(accessoriesComment);
        }
        if (_.has(subOrder, "removeFurniture")) {
          comments.push(this.vstagingComments.removeFurniture + (subOrder.removeFurniture ? "Yes" : "No"));
        }
        if (subOrder.removeFurniture && subOrder.renovateRooms === false) {
          comments.push("Please only remove furniture, but don’t change floorings, windows, ceilings or walls.");
        }
        if (_.has(subOrder, "numberOfWalls")) {
          comments.push(
            this.vstagingComments.numberOfWalls + subOrder.numberOfWalls + (subOrder.numberOfWalls > 0 ? "Yes" : "No")
          );
        }
        // in virtual staging order, comments are stored in description field
        if (_.has(subOrder, "description") && !_.isEmpty(subOrder.description)) {
          const customerComments = await this.ts.translate(subOrder.description);
          comments.push(this.customerComment + customerComments);
        }

        if (subOrder.orderType === OrderTypeEnum.Retouching) {
          if (subOrder.hasOwnProperty("requiredDuration")) {
            comments.push("Required duration: " + subOrder.requiredDuration + " seconds");
          }
          if (subOrder.logo) {
            if (subOrder.hasOwnProperty("logoAllTheTime")) {
              comments.push(
                "Logo all the time: " + (subOrder.logoAllTheTime ? "Yes (Bottom right corner of the video)" : "No")
              );
            }
            if (subOrder.hasOwnProperty("logoAtBeginning")) {
              comments.push("Logo at the beginning: " + (subOrder.logoAtBeginning ? "Yes" : "No"));
            }
            if (subOrder.hasOwnProperty("logoAtEnd")) {
              comments.push("Logo at the end: " + (subOrder.logoAtEnd ? "Yes" : "No"));
            }
          }
          if (
            parseMixedVal(subOrder.packageNum) === CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_VIDEO &&
            subOrder.hasOwnProperty("backgroundMusic")
          ) {
            comments.push("Background music: " + (subOrder.backgroundMusic ? "Yes" : "No"));
          }
          if (subOrder.backgroundMusic && subOrder.selectedBackgroundMusic) {
            comments.push(
              "Selected music title: " +
                subOrder.selectedBackgroundMusic.id +
                " " +
                subOrder.selectedBackgroundMusic.title +
                " (" +
                subOrder.selectedBackgroundMusic.src +
                ")"
            );
          }
          if (
            parseMixedVal(subOrder.packageNum) === CONSTANTS.PACKAGE_KEYS.DRONEMEDIA_VIDEO &&
            subOrder.hasOwnProperty("introOrOutro")
          ) {
            comments.push("Intro or Outro: " + (subOrder.introOrOutro ? "Yes" : "No"));
          }

          if (subOrder.roomNames) {
            comments.push("Display Room Names" + subOrder.roomNames);
          }
        }

        // add comments by the adminstrator too
        let adminComments = "";

        if (
          _.has(subOrder, "adminComments") &&
          !_.isEmpty(subOrder.adminComments) &&
          subOrder.adminComments !== subOrder.retouchingComment &&
          subOrder.adminComments !== subOrder.description
        ) {
          adminComments += subOrder.adminComments;
        }
        if (adminComments) {
          comments.push(this.imogentComment + adminComments);
        }

        if (subOrder.blurOptions && subOrder.blurOptions !== BlurOptions.PhotosDocumentsPlates) {
          comments.push("Blur Options: " + JSON.stringify(subOrder.blurOptions));
        }

        if (CONSTANTS.VIDEO_TOUR_PACKAGES.includes(subOrder.packageNum.split("|").pop())) {
          comments.push("Use title: " + (subOrder.title ? "Yes" : "No"));
        } else if (subOrder.title) {
          comments.push("Use title: " + (subOrder.id.substring(0, 6).toUpperCase() + " - " + subOrder.title));
        }
        if (subOrder.primaryTitle) {
          comments.push("Main Title: " + subOrder.primaryTitle);
        }
        if (subOrder.secondaryTitle) {
          comments.push("Secondary Title: " + subOrder.secondaryTitle);
        }

        if (
          subOrder.orderType === OrderTypeEnum.V_Staging &&
          subOrder.configurationObjects?.length > 0 &&
          subOrder.configurationObjects.filter((c) => c.selectedStyle || c.comment).length > 0
        ) {
          await this.translateSuborderConfigObjects(subOrder)
            .then((updatedSuborder) => {
              updatedSuborder.configurationObjects = updatedSuborder.configurationObjects.filter(
                (config) => config.selectedStyle || config.comment
              );
              updatedSuborder.configurationObjects.forEach((config) => {
                comments.push(
                  "Modification Type: " +
                    config.adminType +
                    ";  Style: " +
                    config.adminStyle +
                    ";  Comments: " +
                    config.adminComment
                );
              });
              comments.push(
                "Modifications file: " + environment.apiBaseUrl + "downloadvisform?oid=" + updatedSuborder.id
              );
            })
            .catch(() => {
              // Translation was not possible so keeping the modification details as it is
              subOrder.configurationObjects = subOrder.configurationObjects.filter(
                (config) => config.selectedStyle || config.comment
              );
              subOrder.configurationObjects.forEach((config) => {
                comments.push(
                  "Modification Type: " +
                    config.type +
                    ";  Style: " +
                    config.selectedStyle +
                    ";  Comments: " +
                    config.comment
                );
              });
              comments.push("Modifications file: " + environment.apiBaseUrl + "downloadvisform?oid=" + subOrder.id);
            });
        }

        // Always get the number of files from firebase since service provider does not always upload exact number of files.
        materials = await this.getMaterials(subOrder);
        quantity = materials.length;

        const isPhotoProduct = !CONSTANTS.PACKAGE_NAMES[OrderTypeEnum.Drone_Media].DRONE_VIDEO;
        if (subOrder.pendingOnSuborderId && isPhotoProduct) {
          // Always get the number of files from firebase since service provider does not always upload exact number of files.
          if (backboneConfig?.backboneUIDs?.includes(subOrder.assignedTo)) {
            quantity = Math.floor(quantity / CONSTANTS.HDR_PHOTO_FACTOR);
          }
        }

        requestBody.params["reference"] = subOrder.id;
        requestBody.params["receivingCompany"] = this.esoftEnvConfig.receivingCompany;
        requestBody.params["orderContacts"] = this.esoftEnvConfig.orderContacts;

        requestBody.params["orderLines"] = [
          {
            productId: esoftProduct.productId,
            quantity: quantity,
            unit: esoftProduct.unit,
            variant1: esoftProduct.variant1,
            comments: [comments.join(" \n")],
          },
        ];
        break;
      case "addMaterialsForBatch":
        requestBody.params["reference"] = subOrder.id;
        requestBody.params["callbackUrl"] =
          this.esoftEnvConfig.callbackUrl +
          `?batchId=${esoftResponse.result.orderLines[0].batchId}&reference=${subOrder.id}`;
        requestBody.params["batchId"] = esoftResponse.result.orderLines[0].batchId;
        requestBody.params["materialsHttp"] = [];

        // add attachments
        materials = await this.getMaterials(subOrder);
        // for digital staging orders with modifications, add the modification pdf
        if (
          subOrder.orderType === OrderTypeEnum.V_Staging &&
          subOrder.configurationObjects?.length > 0 &&
          subOrder.configurationObjects.filter((c) => c.selectedStyle || c.comment).length > 0
        ) {
          const modificationFile = await this.fs
            .callFunction("getmodificationfileinfo?suborderid=" + subOrder.id, {})
            .toPromise();
          materials.push(modificationFile);
        }
        requestBody.params["materialsHttp"] = materials;
        // also add logo if it is in the subOrder
        if (subOrder.logo && subOrder.logoImage && subOrder.logoImage.url) {
          requestBody.params.materialsHttp.push({
            url: subOrder.logoImage.url,
            name: subOrder.logoImage.fileName,
            size: subOrder.logoImage.fileSize,
          });
        }
        // if materials to be sent to esoft were not found, log the error in the suborder
        if (materials.length < 1) {
          const errorMsg = "Unable to get materials from the firebase for subOrder";
          console.log(errorMsg, subOrder);
          this.logErrorToSuborder(subOrder, errorMsg);
        }
        break;
    }
    console.log(JSON.stringify(requestBody.params["orderLines"], null, 4));
    // Team Product complains that sometimes the adminComments are not send to esoft.
    // Because we were not able to reproduce this error we are logging the request that are send
    // to esoft to get more information of this issue
    // LOGGING: Log esoft request to database in order to debug it.
    this.logEsoftRequest(requestBody);

    return requestBody;
  }

  translateSuborderConfigObjects(subOrder): Promise<SubOrder> {
    const translationSources = [];
    const indices = [];
    const configObjects = subOrder.configurationObjects.filter((field, index) => {
      if (field.selectedStyle || field.comment) {
        indices.push(index);
        return true;
      }
      return false;
    });

    configObjects.forEach((config) => {
      translationSources.push(config.type);
      translationSources.push(config.selectedStyle);
      translationSources.push(config.comment);
    });
    return new Promise((resolve, reject) => {
      this.ts.getTranslation(translationSources).subscribe(
        (responseBody: any) => {
          if (responseBody.data && responseBody.data.translations) {
            const translations = responseBody.data.translations;
            for (let i = 0; i < translations.length - 2; i = i + 3) {
              const index = indices.shift();
              subOrder.configurationObjects[index].adminType = translations[i].translatedText || "";
              if (translations[i + 1]) {
                subOrder.configurationObjects[index].adminStyle = translations[i + 1].translatedText || "";
              }
              if (translations[i + 2]) {
                subOrder.configurationObjects[index].adminComment = translations[i + 2].translatedText || "";
              }
            }
          }
          resolve(subOrder);
        },
        (error) => {
          console.log(error);
          reject();
        }
      );
    });
  }

  /**
   * Get attachments/materials from the suborder to send them to esoft
   *
   * @param {*} subOrderId
   * @param {*} subCollectionName
   * @returns {Promise<boolean>}
   * @memberof EsoftApiService
   */
  async getMaterials(subOrder: any): Promise<any> {
    let materialsSuborderId = "";
    let materialsSubcollectionName = "";
    let materials: any = [];
    let helpFiles: any = [];
    if (!_.isEmpty(subOrder.pendingOnSuborderId)) {
      // if this order is pending on another suborder then get photos from that suborder
      materialsSuborderId = subOrder.pendingOnSuborderId;
      materialsSubcollectionName = FileUploadListTargetEnum.OutputPhotos;
    } else {
      materialsSuborderId = subOrder.id;
      materialsSubcollectionName = FileUploadListTargetEnum.OriginalPhotos;
    }

    [materials, helpFiles] = await Promise.all([
      this.getMaterialsFromFirebase(materialsSuborderId, materialsSubcollectionName),
      subOrder.orderType === OrderTypeEnum.V_Staging || subOrder.orderType === OrderTypeEnum.Retouching
        ? this.getMaterialsFromFirebase(subOrder.id, FileUploadListTargetEnum.HelpFiles)
        : [],
    ]);
    // if materials not found, look in the feedback collection
    if (materials.length < 1) {
      materialsSubcollectionName = "feedback1_outputPhotos";
      materials = await this.getMaterialsFromFirebase(materialsSuborderId, materialsSubcollectionName);
    }

    return [...materials, ...helpFiles];
  }

  /**
   * Get materials/attachments from firebase, prepare an array and return
   *
   * @param {string} subOrderId
   * @param {string} subcollectionName
   * @returns
   * @memberof EsoftApiService
   */
  getMaterialsFromFirebase(subOrderId: string, subcollectionName: string) {
    return new Promise((resolve) => {
      const materials = [];
      this.uploadService
        .getSubcollection("suborders", subOrderId, subcollectionName)
        .subscribe((photoCollection: SubcollectionFileModel[]) => {
          photoCollection.forEach((doc) => {
            if (doc.isMarked === undefined || doc.isMarked === true) {
              materials.push({
                url: doc.download_url,
                name: doc.file_name,
                size: doc.file_size,
              });
            }
          });
          resolve(materials);
        });
    });
  }

  /**
   * find a mapping product from esoft which should be ordered for this suborder
   *
   * @param {*} suborder
   * @memberof EsoftApiService
   */
  getEsoftProductDetails(subOrder: SubOrder) {
    return this.getEsoftArticle(subOrder);
  }

  initializeEsoftDBConfig() {
    // fetch esoft configuration from firebase
    return new Promise<void>((resolve) => {
      this.$esoftDBConfigSub = this.conf.getConfiguration(CONSTANTS.CONFIGURATIONS.ESOFT).subscribe((data) => {
        this.esoftDBConfig = data;
        resolve();
      });
    });
  }

  /**
   * Implementation of esoft method createOrder
   * @param subOrder Suborder to be created
   * @memberof EsoftApiService
   */
  async createOrder(subOrder: SubOrder): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      // Only create order on esoft if esoft api is enabled
      if (_.isEmpty(this.esoftDBConfig)) {
        await this.initializeEsoftDBConfig();
      }
      if (!this.esoftDBConfig.apiEnabled) {
        resolve();
        return;
      }
      // check if esoft api is enabled for virtual staging
      if (subOrder.orderType === "vstaging" && !this.esoftDBConfig.vstagingEnabled) {
        resolve();
        return;
      } else if (subOrder.orderType === "retouching") {
        // check if esoft api is enabled for retouching according to the type of order (picture retouching or video retouching)
        if (
          (!this.esoftDBConfig.pictureRetouchingEnabled && !(this.videoPackages.indexOf(subOrder.packageNum) > -1)) ||
          (!this.esoftDBConfig.videoRetouchingEnabled && this.videoPackages.indexOf(subOrder.packageNum) > -1)
        ) {
          resolve();
          return;
        }
      }
      /** if this subOrder already has a esoftBatchId, it means the order is already created on esoft
       No need to send createOrder call again in that case */
      if (subOrder.esoft && subOrder.esoft.esoftBatchId) {
        const preparedResponse = {
          result: { orderLines: [{ batchId: subOrder.esoft.esoftBatchId }] },
        };
        this.addMaterialsForBatch(subOrder, preparedResponse)
          .then(() => resolve())
          .catch(() => reject(false));
      } else {
        const method = "createOrder";
        let body;
        await this.prepareRequestBody(method, subOrder)
          .then((data) => (body = data))
          .catch(() => reject());
        if (!body) {
          // No related esoft product found, the suborder is not integrated yet with esoft
          resolve();
          return;
        }
        const options = this.getDefaultOptions();
        this.jsonRpc
          .makeRequest(body, options)
          .then(async (response) => {
            console.log("response of createOrder esoft: ", response);
            if (response.result) {
              this.handleSuccessResponse(method, response, subOrder, body)
                .then(() => resolve())
                .catch(() => reject());
            } else {
              this.handleErrorResponse(method, response, subOrder);
              reject();
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            reject();
          });
      }
    });
  }

  /**
   * Implementation of esoft method addMaterialsForBatch
   * @param subOrder: Suborder to be created
   * @memberof EsoftApiService
   */
  addMaterialsForBatch(subOrder: any, createOrderResponse: any) {
    return new Promise<void>(async (resolve, reject) => {
      // check if materials for batch are already added
      if (subOrder.esoft && subOrder.esoft.addMaterialsForBatchResponse === "materialsAdded") {
        console.log("Materials already addded for batch");
        resolve();
      }
      const method = "addMaterialsForBatch";
      let body;
      await this.prepareRequestBody(method, subOrder, createOrderResponse)
        .then((data) => (body = data))
        .catch(() => reject());
      if (!body) {
        const errorMsg = "Unable to prepare request body for esoft";
        console.log(errorMsg);
        this.logErrorToSuborder(subOrder, errorMsg);
        resolve();
        return;
      }
      const options = this.getDefaultOptions();
      this.jsonRpc
        .makeRequest(body, options)
        .then((response) => {
          console.log("response of addMaterialsForBatch esoft: ", response);
          if (response.result) {
            this.handleSuccessResponse(method, response, subOrder, body)
              .then(() => resolve())
              .catch(() => reject());
          } else {
            this.handleErrorResponse(method, response, subOrder);
            reject();
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject();
        });
    });
  }

  /**
   * Handle successfull response of esoft api call
   *
   * @param method  esoft method name for which the success response needs to be handled
   * @param response esoft API response
   * @oaram subOrder corresponding SubOrder
   * @memberof EsoftApiService
   */
  handleSuccessResponse(method: string, response: any, subOrder: SubOrder, body: any) {
    return new Promise<void>((resolve, reject) => {
      if (!response.result) {
        reject();
      }
      switch (method) {
        case "createOrder":
          if (_.isArray(response.result.orderLines) && !_.isUndefined(response.result.orderLines[0].batchId)) {
            const newData = {
              ...subOrder.esoft,
              esoftBatchId: response.result.orderLines[0].batchId,
              esoftProductId: response.result.orderLines[0].productId,
              esoftWorkflowStep: method,
              esoftProductVariant: response.result.orderLines[0].variant1,
            };
            subOrder.esoft = newData;
            this.http
              .patch<string>(this.subordersEndpoint + "/" + subOrder.id, {
                esoft: newData,
                serviceProviderOrderId: response.result.orderLines[0].batchId,
              })
              .subscribe();
            this.addMaterialsForBatch(subOrder, response)
              .then(() => resolve())
              .catch(() => reject());
          }
          break;
        case "addMaterialsForBatch":
          const updateData = {
            ...subOrder.esoft,
            addMaterialsForBatchResponse: response.result,
            esoftWorkflowStep: method,
            materialSentDate: new Date(),
            numberOfFilesTransfered: body?.params["materialsHttp"]?.length || 0,
          };
          subOrder.esoft = updateData;
          this.http.patch<string>(this.subordersEndpoint + "/" + subOrder.id, { esoft: updateData }).subscribe();
          resolve();
          break;
      }
    });
  }

  /**
   * Handle esoft error response. Save the respone in subOrder for tracking
   *
   * @param {*} method Esfot method name
   * @param {*} response
   * @param {*} [subOrder]
   * @returns
   * @memberof EsoftApiService
   */
  handleErrorResponse(method: string, response: any, subOrder?: any) {
    if (!response.error) {
      return;
    }
    this.ns.showNotification(`esoft error ${response.error.code} : ${response.error.message}`, "danger");
    if (subOrder) {
      const args = {};
      args[method + "Response"] = response;
      const newData = { ...subOrder.esoft, ...args };
      this.http.patch(this.subordersEndpoint + "/" + subOrder.id, { esoft: newData }).subscribe();
      // if this suborder has parent suborder on which it was dependent, change its status back to uploaded
      if (subOrder.status === "pending" && subOrder.pendingOnSuborderId) {
        this.http
          .patch(this.subordersEndpoint + "/" + subOrder.pendingOnSuborderId, { status: "uploaded" })
          .subscribe();
      }
    }
  }

  /**
   * Function to store esoft related error message to subOrder
   *
   * @param {string} subOrderId
   * @param {string} errorMsg
   * @memberof EsoftApiService
   */
  logErrorToSuborder(subOrder: SubOrder, errorMsg: string) {
    // add error with timestamp as key
    const timeString = new Date().toTimeString();
    const newData = { ...subOrder.esoft, timeString: errorMsg };
    this.http.patch(this.subordersEndpoint + "/" + subOrder.id, { esoft: newData }).subscribe();
  }

  /**
   * Send email to esoft for the floorplan
   *
   * @param floorplanId
   * @param vstaging
   * @memberof EsoftApiService
   */
  async sendFloorplanEmail(floorplanId: string, vstagingSubOrder: SubOrder) {
    if (_.isEmpty(this.esoftDBConfig)) {
      await this.initializeEsoftDBConfig();
    }
    // check if esoft api is enabled
    if (!this.esoftDBConfig.apiEnabled) {
      return;
    }
    // check if esoft api is enabled for virtual staging
    if (!this.esoftDBConfig.vstagingEnabled) {
      return;
    }
    // check if esoft api is enabled for floorplan emails for Virtual tour
    if (!this.esoftDBConfig.floorplanEmailEnabled) {
      return;
    }
    if (
      vstagingSubOrder.withFloorplan &&
      vstagingSubOrder.esoft &&
      vstagingSubOrder.esoft["esoftBatchId"] &&
      !vstagingSubOrder.esoft["floorplanEmailSent"]
    ) {
      console.log("sending floorplan email");
      this.fs
        .callFunction("esoftEmailHandler", {
          emailType: "floorplan",
          suborderId: floorplanId,
        })
        .subscribe((result) => {
          console.log("floorplan email sent:", result);
          if (result["data"] && result["data"]["emailSent"]) {
            // update vstaging suborder
            const newData = { ...vstagingSubOrder.esoft, floorplanEmailSent: true };
            this.http.patch(this.subordersEndpoint + "/" + vstagingSubOrder.id, { esoft: newData });
          }
        });
    }
  }

  /**
   * Logs requests that are send to esoft to our database in order to make error
   * understanable.
   * @param requestBody The complete (JSON) request that is send to esoft
   */
  logEsoftRequest(requestBody) {
    this.http
      .post(`${environment.apiUrl}${CONSTANTS.BACKEND_ENDPOINTS.CONFIGURATION}/logs/esoft`, requestBody)
      .subscribe((response) => {
        console.log(response);
      });
  }

  sendFeedbackToEsoft(requestBody: OrderRejectionMail) {
    return lastValueFrom(this.http.post(this.feedbacksEndpoint + "/send-feedback", requestBody));
  }

  sendFeedbackToEsoftBySuborderId(suborderId: string, feedbackNumber?: number) {
    const requestBody = {
      suborderId: suborderId,
      feedbackNumber: feedbackNumber,
    };
    return lastValueFrom(this.http.post(this.feedbacksEndpoint + "/send-feedback-by-suborder-id", requestBody));
  }

  ngOnDestroy() {
    if (!_.isEmpty(this.$esoftDBConfigSub) && _.isFunction(this.$esoftDBConfigSub.unsubscribe)) {
      this.$esoftDBConfigSub.unsubscribe();
    }
    // unsubscript esoft service related subscriptions
    this.subscriptions.forEach((sub) => {
      if (_.isFunction(sub.unsubscribe)) {
        sub.unsubscribe();
      }
    });
  }
}
