import { Injectable } from "@angular/core";
import { OrderStatusEnum } from "@app/models/order-status-list";
import { SubOrder } from "@app/interfaces/suborder.interface";
import { AuthService } from "@app/auth/auth.service";
import { WebsocketsService } from "@app/_services/websockets.service";
import { lastValueFrom, Subscription } from "rxjs";
import { SuborderInfo } from "@app/interfaces/order.interface";
import { skip, take } from "rxjs/operators";
import { UserRoleEnum } from "@app/models/user-role-list";
import { OrdersService } from "@app/_services/orders.service";
import { CONSTANTS } from "@app/util/constants";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { addBusinessDaysToDate, parseMixedVal, shortener } from "@app/util/helper";
import { ConfigurationLogicService } from "@app/_services/configuration-logic.service";
import { FairFleetService } from "@app/_services/fair-fleet.service";
import { McGrundrissLogicService } from "@app/_services/mc-grundriss-logic.service";
import { DocEstateService } from "@app/_services/docestate.service";
import { EsoftApiService } from "@app/_services/esoft-api.service";
import { SubordersService } from "@app/_services/suborders.service";
import { SuborderStatusDates } from "@app/interfaces/suborder-status-dates.interface";
import { OrderMailService } from "@app/_services/order-mail.service";
import { SendEmailService } from "./send-email.service";
import { environment } from "@environments/environment";
import * as moment from "moment";
import { OffenblendeConfig } from "@app/interfaces/offenblende-config.interface";
import { OffenblendeService } from "@app/_services/offenblende.service";
import { DocEstateSuborder } from "@app/interfaces/document-procurement-package.interface";
import { User } from "@app/interfaces/user.interface";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { NotificationsService } from "./notifications.service";
import { MoovinLogicService } from "@app/_services/moovin-logic.service";
import { GrundrissSchmiedeService } from "@app/_services/grundriss-schmiede.service";
import { McGrundrissConfig } from "@app/interfaces/mcgrundriss-config-data.interface";
import { FairfleetConfig } from "@app/interfaces/fairfleet-config.interface";
import { ImmoviewerConfig } from "@app/interfaces/immoviewer-config.interface";
import { ImmoviewerLogicService } from "@app/_services/immoviewer-logic.service";
import { RenewaService } from "@app/_services/renewa.service";
import { GrundrissSchmiedeConfig } from "@app/interfaces/grundrissSchmiedeConfig.interface";
import { BackboneConfig } from "@app/interfaces/backbone-config.interface";
import { BackboneLogicService } from "@app/_services/backbone-logic.service";

@Injectable({
  providedIn: "root",
})
export class StatusLogicService {
  private readonly logService = "StatusLogicService :: ";
  private allSubordersSubscription: Subscription;
  private ffConfig: FairfleetConfig;
  private offenblendeConfig: OffenblendeConfig;
  private backboneConfig: BackboneConfig;
  private mcGrundrissConfig: McGrundrissConfig;
  private immoviewerConfig: ImmoviewerConfig;
  private grundrissSchmiedeConfig: GrundrissSchmiedeConfig;
  private updateSub: Subscription;

  constructor(
    private ses: SendEmailService,
    private auth: AuthService,
    private cs: ConfigurationLogicService,
    private des: DocEstateService,
    private esClient: EsoftApiService,
    private bkbnLogicService: BackboneLogicService,
    private offenblendeService: OffenblendeService,
    private ns: NotificationsService,
    private mcls: McGrundrissLogicService,
    private oms: OrderMailService,
    private os: OrdersService,
    private sos: SubordersService,
    private uls: UsersLogicService,
    private ws: WebsocketsService,
    private gms: GrundrissSchmiedeService,
    private moovinService: MoovinLogicService,
    private ivls: ImmoviewerLogicService,
    private renewaService: RenewaService
  ) {}

  /**
   * Sets the status to 'checked' for all suborders of an order. Some suborder types are set to 'processing' instead.
   * @param orderIdParam The order id to search for the suborders.
   */
  async setStatusChecked(orderIdParam: string) {
    this.auth.showLoader.emit(true);
    // update the status of child suborders

    let noOfUpdatesRequired = 1;
    this.ws.allSuborders.value.forEach((suborder) => {
      if (suborder["status"] === OrderStatusEnum.Opened || suborder["status"] === OrderStatusEnum.Pending) {
        noOfUpdatesRequired++;
      }
    });
    let noOfUpdatesPerformed = 0;

    // Subscribe to allSuborders to keep track of the suborder updates reflected in the BehaviourSubject. When all suborderupdates are
    // available in allSuborders call updateOrderStatus
    this.ws.getSuborders(orderIdParam);
    this.allSubordersSubscription = this.ws.allSuborders.subscribe((data) => {
      noOfUpdatesPerformed++;
      if (noOfUpdatesPerformed >= noOfUpdatesRequired) {
        // Minimal number of updates were all suborders could be updated.
        let allSubordersProcessed = true;
        // Check if all suborders in data don't have status == 'opened'
        for (const suborder of data) {
          if (suborder.status === OrderStatusEnum.Opened) {
            allSubordersProcessed = false;
            break;
          }
        }
        if (allSubordersProcessed) {
          this.allSubordersSubscription.unsubscribe();
          this.auth.showLoader.emit(false);
        }
      }
    });

    for (let index = 0; index < this.ws.allSuborders.value.length; index++) {
      let suborder: SubOrder = this.ws.allSuborders.value[index];
      await this.approveSuborder(suborder);
    }
  }

  async updateOrderStatusSync(orderId) {
    this.ws.getSuborders(orderId);
    const suborders = await this.ws.allSuborders.pipe(skip(1), take(1)).toPromise();
    await this.updateOrderStatusBySuborders(orderId, suborders);
    this.ws.unsubscribeFromSuborders();
  }

  // Updates status related properties in the main order.
  // In most cases the status is updated in the cloud function on each update of a suborder by a trigger from firestore.
  // But for specific cases (invoices) we need a synchronous execution of the
  // status update, thus, we keep this function here, too.
  // TODO: The logic is duplicated in the cloud functions, find a way to only use one function.
  async updateOrderStatusBySuborders(orderId, suborders) {
    let allSubordersExist = true;
    const allSubordersInfo: SuborderInfo[] = [];
    const currentOrder = await this.os.getOrder(orderId);
    if (!currentOrder.allSuborders || !currentOrder.allSuborders.length) {
      allSubordersExist = false;
    }
    // TODO: This is a try to fix IMO-612: The assumption is that in some cases either suborders is empty or undefined and thus, accepted is set to true falsy.
    // TODO: If it turns out to be true, try to fix the underlying reason!
    if (!allSubordersExist || (suborders?.length && currentOrder.allSuborders.length === suborders?.length)) {
      let processing = true;
      let completed = true;
      let accepted = true;
      let serviceProviderActionRequired = false;
      let adminActionRequired = false;
      let adminUpdate = false;
      let customerActionRequired = false;
      let cancelled = true;

      // Update allSuborders array of the order.
      suborders.forEach((element: SubOrder) => {
        const statusDatesFeedbacks = element.statusDates.feedbacks[element.feedbackNumber - 1];
        if (!allSubordersExist) {
          allSubordersInfo.push({
            suborderId: element.id,
            packageNames: element.packageName ? element.packageName : element.orderType,
            packageNumbers: element.packageNum,
            packageTypes: element.orderType,
            suborderStatus: element.status,
            nextActionBy: element.adminActionRequired
              ? UserRoleEnum.Administrator
              : element.customerActionRequired
              ? UserRoleEnum.Customer
              : element.serviceProviderActionRequired
              ? UserRoleEnum.ServiceProvider
              : "",
            curEstimatedDelivery: statusDatesFeedbacks?.estimatedDelivery,
            curUploaded: statusDatesFeedbacks?.uploaded,
            curDelivery: statusDatesFeedbacks?.delivery,
          });
        } else {
          const foundSuborder = currentOrder.allSuborders.find((sub) => sub.suborderId === element.id);
          if (foundSuborder) {
            let nextAction = "";
            if (element.adminActionRequired) {
              nextAction = UserRoleEnum.Administrator;
            } else if (element.customerActionRequired) {
              nextAction = UserRoleEnum.Customer;
            } else if (element.serviceProviderActionRequired) {
              nextAction = UserRoleEnum.ServiceProvider;
            }

            foundSuborder.suborderStatus = element.status;
            foundSuborder.nextActionBy = nextAction;
            foundSuborder.curEstimatedDelivery = statusDatesFeedbacks?.estimatedDelivery;
            foundSuborder.curUploaded = statusDatesFeedbacks?.uploaded;
            foundSuborder.curDelivery = statusDatesFeedbacks?.delivery;
          }
        }

        if (element.status === OrderStatusEnum.Opened) {
          processing = false;
        }
        if (element.status !== OrderStatusEnum.Completed && element.status !== OrderStatusEnum.Accepted) {
          completed = false;
        }
        if (element.status !== OrderStatusEnum.Accepted) {
          accepted = false;
        }
        if (element.status !== OrderStatusEnum.Canceled) {
          cancelled = false;
        }
        if (element.serviceProviderActionRequired) {
          serviceProviderActionRequired = true;
        }
        if (
          element.adminActionRequired ||
          element.status === OrderStatusEnum.Opened ||
          element.status === OrderStatusEnum.Requested ||
          element.status === OrderStatusEnum.Uploaded
        ) {
          adminActionRequired = true;
        }
        if (element.adminUpdate) {
          adminUpdate = true;
        }
        if (element.customerActionRequired) {
          customerActionRequired = true;
        }
      });
      let status = OrderStatusEnum.Opened;
      if (processing) {
        status = OrderStatusEnum.Processing;
      }
      if (completed) {
        status = OrderStatusEnum.Completed;
      }
      if (accepted) {
        status = OrderStatusEnum.Accepted;
      }
      if (cancelled) {
        status = OrderStatusEnum.Canceled;
      }

      let approvedOn: Date;
      if (processing && !currentOrder.approvedOn) {
        approvedOn = new Date();
      }

      let completedOn: Date;
      if ((completed || accepted) && !currentOrder.completedOn) {
        completedOn = new Date();
      }

      await this.os.patchOrder(orderId, {
        allSuborders: allSubordersExist ? currentOrder.allSuborders : allSubordersInfo,
        status: status,
        adminActionRequired: adminActionRequired,
        adminUpdate: adminUpdate,
        customerActionRequired: customerActionRequired,
        serviceProviderActionRequired: serviceProviderActionRequired,
        approvedOn: approvedOn,
        completedOn: completedOn,
      });
    }
  }

  /**
   * Sets the suborder into processing state and creates the corresponding order either via API (if implemented) or via E-Mail.
   * @param suborder The SubOrder data
   */
  // TODO: This logic should be completely moved to the backend such that we don't need to fetch the configurations.
  //  See: IMO-1186
  approveSuborder(suborder: SubOrder) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (suborder.status === OrderStatusEnum.Opened) {
          const dronePhotographyConfigPromises = [
            this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.OFFENBLENDE).toPromise(),
          ];

          dronePhotographyConfigPromises.push(
            lastValueFrom(this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.BACKBONE))
          );

          if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
            dronePhotographyConfigPromises.push(
              lastValueFrom(this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.FAIRFLEET))
            );
          }

          const dronePhotographyConfigs = await Promise.all(dronePhotographyConfigPromises);
          this.offenblendeConfig = dronePhotographyConfigs[0] as OffenblendeConfig;
          this.backboneConfig = dronePhotographyConfigs[1] as BackboneConfig;
          if (this.auth.myUserObservable.role === UserRoleEnum.Administrator) {
            this.ffConfig = dronePhotographyConfigs[2] as FairfleetConfig;
          }

          let status = OrderStatusEnum.Checked;

          console.log(this.backboneConfig);
          console.log(this.backboneConfig.isCreateOrderEnabled);
          console.log(this.backboneConfig.backbonePackageNums.includes(parseMixedVal(suborder.packageNum)));
          console.log(this.backboneConfig.backboneUIDs.includes(suborder.assignedTo));

          if (
            this.offenblendeConfig?.isCreateOrderEnabled &&
            this.offenblendeConfig.offenblendeProducts.includes(suborder.packageNum.split("|").pop()) &&
            this.offenblendeConfig.offenblendeUIDs.includes(suborder.assignedTo)
          ) {
            await this.handleOffenblendeOrderCreation(suborder.orderId);
          } else if (
            this.backboneConfig.isCreateOrderEnabled &&
            this.backboneConfig.backbonePackageNums.includes(parseMixedVal(suborder.packageNum)) &&
            this.backboneConfig.backboneUIDs.includes(suborder.assignedTo)
          ) {
            await this.bkbnLogicService.createOrder(suborder.orderId);
          } // floorplan
          else if (suborder.orderType === OrderTypeEnum.Floor_Plan) {
            const floorplanConfigPromises = [
              this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.MC_GRUNDRISS).toPromise(),
              this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.IMMOVIEWER).toPromise(),
              this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.GRUNDRISS_SCHMIEDE).toPromise(),
            ];

            const floorplanConfigs = await Promise.all(floorplanConfigPromises);

            this.mcGrundrissConfig = floorplanConfigs[0] as McGrundrissConfig;
            this.immoviewerConfig = floorplanConfigs[1] as ImmoviewerConfig;
            this.grundrissSchmiedeConfig = floorplanConfigs[2] as GrundrissSchmiedeConfig;

            if (
              CONSTANTS.CONFIGURATIONS.MC_GRUNDRISS_PACKAGES.includes(suborder.packageNum.split("|").pop()) &&
              suborder.assignedTo === this.mcGrundrissConfig?.userIdMcGrundrissAtImogent
            ) {
              // floorplan package supported for API
              this.mcls.createOrderBackend(suborder.id);
            } else if (
              this.immoviewerConfig.floorplanPackageNums.includes(suborder.packageNum.split("|").pop()) &&
              suborder.assignedTo === this.immoviewerConfig.apiUserId
            ) {
              await this.ivls.createFloorplanOrder(suborder.id);
            } else if (this.grundrissSchmiedeConfig.factSheetPackages.includes(suborder.packageNum.split("|").pop())) {
              await this.gms.createFactSheetsOrder(suborder.id);
            } else {
              this.oms.sendConfirmationEmail(suborder.orderId, "serviceProvider");
            }
            await this.sos.patchSuborder(suborder.id, {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false,
              serviceProviderActionRequired: true,
            });
            // Retouching
          } else if (
            suborder.orderType === OrderTypeEnum.Retouching ||
            (suborder.orderType === OrderTypeEnum.V_Staging && suborder.qualitySelection !== 1) ||
            (suborder.orderType === OrderTypeEnum.Video_Tour && suborder.selectedPackage === "DIY Objektbegehung")
          ) {
            console.log(this.logService + "Order creation on esoft handled");
            // create order on esoft first
            await this.esClient.createOrder(suborder);
            await this.sos.patchSuborder(suborder.id, {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false,
              serviceProviderActionRequired: true,
            });
            // documents procurement
          } else if (suborder.orderType === OrderTypeEnum.Documents_Procurement) {
            const relatedSuborders = (await this.sos.getRelatedSuborders(suborder.id)) as DocEstateSuborder[];
            // Exceptions from order creation is being handled in the catch block already
            // Making it synchronous is needed to make sure the order status is updated correctly on Elastic document
            await this.des.placeDocEstateOrder(suborder.orderId).toPromise();
            const updatingSuborders = [];
            // eslint-disable-next-line @typescript-eslint/no-shadow
            relatedSuborders.forEach((suborder) => {
              const statusDates = suborder.statusDates;
              statusDates[OrderStatusEnum.Processing] = new Date();
              if (
                suborder.docEstateProductsList?.length &&
                CONSTANTS.DOCESTATE_ESTIMATED_DELIVERY_TIME[suborder.docEstateProductsList[0]]
              ) {
                const estimatedDeliveryDuration =
                  CONSTANTS.DOCESTATE_ESTIMATED_DELIVERY_TIME[suborder.docEstateProductsList[0]];
                statusDates.feedbacks[0].estimatedDelivery = moment(new Date())
                  .add(estimatedDeliveryDuration.value, estimatedDeliveryDuration.unit)
                  .toDate();
              }
              updatingSuborders.push(
                this.sos.patchSuborder(suborder.id, {
                  status: OrderStatusEnum.Processing,
                  statusDates: statusDates,
                  adminActionRequired: false,
                  serviceProviderActionRequired: true,
                })
              );
            });
            await Promise.all(updatingSuborders);
            // hdphotos
          } else if (
            suborder.packageNum === CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_BACKBONE ||
            suborder.packageNum.endsWith("|" + CONSTANTS.PACKAGE_KEYS.HD_PHOTOS_BACKBONE)
          ) {
            this.oms.sendConfirmationEmail(suborder.orderId, "backbone");
            await this.sos.patchSuborder(suborder.id, {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false,
              serviceProviderActionRequired: true,
            });
          } else if (suborder.orderType === OrderTypeEnum.Energy_Pass) {
            if (parseMixedVal(suborder.packageNum) !== CONSTANTS.PACKAGE_KEYS.ENERGY_ASSESSMENT) {
              await this.moovinService.createOrder(suborder.id);
            } else {
              await this.renewaService.createOrder(suborder.id);
            }
            await this.sos.patchSuborder(suborder.id, {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false,
              serviceProviderActionRequired: true,
              statusDates: suborder.statusDates,
            });
          } else if (suborder.packageNum.split("|").pop() === CONSTANTS.PACKAGE_KEYS.ON_SITE_AREA_CALC) {
            this.oms.sendConfirmationEmail(suborder.orderId, "measurement");
            await this.sos.patchSuborder(suborder.id, {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false,
              serviceProviderActionRequired: true,
            });
          } else if (suborder.packageNum.split("|").pop() === CONSTANTS.PACKAGE_KEYS.FLOORPLAN_BASED_AREA_CALC) {
            await this.gms.createOrder(suborder.id);
            await this.sos.patchSuborder(suborder.id, {
              status: OrderStatusEnum.Processing,
              adminActionRequired: false,
              serviceProviderActionRequired: true,
            });
          } else {
            // For other orders there is (until now) no API Integration, so we just update the suborder.
            await this.sos.patchSuborder(suborder.id, {
              adminActionRequired: suborder.feedbacks_included && suborder.feedbacks_included > 0,
              status: status,
            });
          }
        } else if (suborder.status === OrderStatusEnum.Pending) {
          await this.sos.patchSuborder(suborder.id, {
            adminActionRequired: false,
          });
        }

        if (suborder.assignedTo && suborder.status !== OrderStatusEnum.Pending) {
          let adminActionRequired;
          let serviceProviderActionRequired;
          const statusDates = suborder.statusDates;
          if (!statusDates.processing) {
            statusDates.processing = new Date();
          }

          // suborders with an active auto pilot, dont require admin action
          if (suborder.hasAutoPilot) {
            adminActionRequired = false;

            // adding 24h to the processing time in order to get the estimated delivery date
            // estimated delivery time for documents procurements and for below mentioned products are handled differently.
            const hideDeliveryDateFor = [
              OrderTypeEnum.Documents_Procurement,
              OrderTypeEnum.Drone_Media,
              OrderTypeEnum.Energy_Pass,
              OrderTypeEnum.Hd_Photos,
              OrderTypeEnum.Virtual_Tour,
              OrderTypeEnum.Video_Tour,
              OrderTypeEnum.Energy_Pass,
              OrderTypeEnum.V_Staging,
            ];
            if (
              !hideDeliveryDateFor.includes(suborder.orderType) &&
              !suborder.defaultDeliveryTime &&
              suborder.packageNum.split("|").pop() !== CONSTANTS.PACKAGE_KEYS.ON_SITE_AREA_CALC &&
              suborder.packageNum.split("|").pop() !== CONSTANTS.PACKAGE_KEYS.FLOORPLAN_BPD // Delivery time for bpd factsheets will be determined individually for each project.
            ) {
              statusDates.feedbacks[0].estimatedDelivery = addBusinessDaysToDate(statusDates.processing, 1);
            } else if (suborder.orderType === OrderTypeEnum.Energy_Pass) {
              statusDates.feedbacks[0].estimatedDelivery = addBusinessDaysToDate(new Date(), 2);
            } else if (suborder.defaultDeliveryTime) {
              statusDates.feedbacks[0].estimatedDelivery = addBusinessDaysToDate(
                statusDates.processing,
                suborder.defaultDeliveryTime
              );
            }
          } else {
            adminActionRequired = suborder.feedbacks_included > 0;
          }

          serviceProviderActionRequired = !adminActionRequired;

          await this.updateStatusDates(
            suborder,
            statusDates,
            adminActionRequired,
            false,
            false,
            serviceProviderActionRequired
          );
        }
        await this.updateOrderStatusSync(suborder.orderId);
        resolve();
      } catch (error) {
        console.log(this.logService + "Error found:", error);
        this.ns.showNotification(error, "danger");
        reject(error);
      }
    });
  }

  /**
   * Updates statusDates and the corresponding flags, given as parameters
   * @param suborder SubOrder Object
   * @param statusDates The status dates of the suborder
   * @param adminActionRequired adminActionRequired flag
   * @param adminUpdate adminUpdate flag
   * @param customerActionRequired customerActionRequired flag
   * @param serviceProviderActionRequired serviceProviderActionRequired flag
   * @param additionalData Additional data that can be added to the suborder
   * @param loadCurrentSuborder Flag whether the algorithm shall wait for the current suborders value to be available in the wss.
   */
  async updateStatusDates(
    suborder: SubOrder,
    statusDates: SuborderStatusDates,
    adminActionRequired: boolean,
    adminUpdate: boolean,
    customerActionRequired: boolean,
    serviceProviderActionRequired: boolean,
    additionalData = {},
    loadCurrentSuborder = true
  ) {
    let data = {
      statusDates: statusDates,
      adminActionRequired: adminActionRequired,
      adminUpdate: adminUpdate,
      customerActionRequired: customerActionRequired,
      serviceProviderActionRequired: serviceProviderActionRequired,
    };
    if (additionalData.constructor === Object && Object.keys(additionalData).length !== 0) {
      data = {
        ...data,
        ...additionalData,
      };
    }
    await this.sos.patchSuborder(suborder.id, data);
  }

  /**
   * Changes the status of a suborder and automatically triggers the order status update
   * @param suborder The suborder object that will be updated
   * @param status the target status
   * @param videoURL If the suborder has a video output true, else false.
   */
  changeSuborderStatus(suborder: SubOrder, status: OrderStatusEnum, videoURL?: boolean) {
    return new Promise<void>(async (resolve, reject) => {
      const statusDates = suborder.statusDates;
      if (!statusDates[status]) {
        statusDates[status] = new Date();
      }
      if (statusDates.completed && status !== OrderStatusEnum.Completed) {
        statusDates.completed = "";
      }
      const data = {
        status: status,
        statusDates: statusDates,
        completedOn: status === OrderStatusEnum.Completed ? new Date() : "",
      };
      if (status === OrderStatusEnum.Completed || status === OrderStatusEnum.Canceled) {
        data["nextActionBy"] = "";
        data["adminActionRequired"] = false;
        data["serviceProviderActionRequired"] = false;
        data["customerActionRequired"] = false;
      }
      if (!videoURL && suborder.outputVideoUrl) {
        data["outputVideoUrl"] = suborder.outputVideoUrl;
      } else if (videoURL) {
        data["outputVideoUrl"] = videoURL;
      }

      await this.sos.patchSuborder(suborder.id, data);
      this.sendStatusChangeEmail(suborder.id, status, statusDates);
      resolve();
    });
  }

  sendStatusChangeEmail(suborderId: string, newStatus: OrderStatusEnum, statusDates: SuborderStatusDates) {
    try {
      if (newStatus !== OrderStatusEnum.Canceled) {
        console.log(this.logService + "Status change email is only sent for canceled states.");
        return;
      }

      const loggedInUserEmail = this.auth.myUserObservable.email;
      const canceledDateFormated = moment(statusDates?.canceled).format("DD.MM.YYYY, HH:mm");
      // inform admin through email
      const emailObj = {
        to: CONSTANTS.SUPPORT_EMAILS.PRODUCTION,
        subject:
          "Der Unterauftrag " +
          suborderId +
          " wurde am " +
          canceledDateFormated +
          " von " +
          loggedInUserEmail +
          " storniert.",
        body: "",
      };

      this.ses.sendEmail(emailObj);
    } catch (err) {
      console.error(this.logService + "Error found while trying to send email.", err);
    }
  }

  /**
   * Sends a e-mail to the service team, when new results have been uploaded by a service provider.
   * @param suborderId id of the suborder
   * @param uploadDate date when the service provider uploaded the files
   * @param orderType the suborders order type
   * @param serviceProviderComments optional comments of the service provider
   */
  sendNewResultNotificationEmail(
    suborderId: string,
    uploadDate: Date | "",
    orderType: OrderTypeEnum,
    serviceProviderComments?: string
  ) {
    try {
      const uploadDateFormatted = moment(uploadDate).format("DD.MM.YYYY, HH:mm");

      let to = CONSTANTS.SUPPORT_EMAILS.PRODUCTION;
      let subject = `Neue Dateien für suborder ${suborderId} (${orderType}) hochgeladen.`;

      if (environment.env !== "prod") {
        to = CONSTANTS.SUPPORT_EMAILS.DEVELOP;
        subject = "Test E-Mail: " + subject;
      }

      const emailObj = {
        to: to,
        subject: subject,
        body: `
                    Für die Suborder <a href=${
                      environment.platformUrl
                    }/orderoverview/suborder/${suborderId}>${suborderId}</a> wurden am ${uploadDateFormatted} neue Dateien hochgeladen.
                    <br>
                    ${serviceProviderComments ? "Feedback des Dienstleisters: " + serviceProviderComments : ""}
                `,
      };

      this.ses.sendEmail(emailObj);
    } catch (e) {
      console.error(this.logService + "Error found while trying to send email.", e);
    }
  }

  async sendPhotoResultRejectionEMail(
    orderId: string,
    suborderId: string,
    address: string,
    product: string,
    comment?: string
  ) {
    try {
      const suborder = await this.ws.allSuborders.value.find((so) => so.id === suborderId);
      let to: string;
      let body = `Sehr geehrte Damen und Herren,<br><br>die Dateien des im Betreff genannten Auftrages wurden von IMOGENT abgelehnt. <br>
      Der Upload für das Produkt ${product} wurde damit erneut freigeschaltet und ist weiterhin unter dem
      <a href=${environment.platformUrl}/orderoverview/suborder/${suborderId}>Link</a> möglich.<br>
      Bitte stellen Sie uns die überarbeiteten Dateien innerhalb der vereinbarten Lieferzeit zur Verfügung.<br><br>
      ${comment ? "Unser Team hat folgende Nachricht hinterlassen: " + comment : ""}`;

      if (suborder?.assignedTo) {
        const targetUser = (await this.uls.getUserDetails(suborder.assignedTo).toPromise()) as User;
        to = targetUser.email;
        const offenblendeConfig = <OffenblendeConfig>(
          await this.cs.getConfiguration(CONSTANTS.CONFIGURATIONS.OFFENBLENDE).toPromise()
        );

        if (offenblendeConfig.offenblendeUIDs.includes(targetUser.uid)) {
          const offenblendeOrderUrl =
            environment.offenblende.portalURL + `jobs/client_task/${suborder.offenblendeAPI?.jobId}.htm`;
          const suborderURL = environment.platformUrl + "orderoverview/suborder/" + suborderId;
          body = `<p>Liebes Offenblende Team,</p>
        <p style="margin-top:0px">die Dateien des im Betreff genannten Auftrages wurden von IMOGENT abgelehnt.</p>
        <p style="margin-top:0px">Der Upload für das Produkt ${product} wurde damit erneut freigeschaltet und ist weiterhin unter dem
                  Link möglich.</p>
                  <a href="${suborderURL}">Direktlink zu Auftrag</a><br>
                  <a href="${offenblendeOrderUrl}">Offenblende Auftrag</a>
                  ${comment ? "<p>Unser Team hat folgende Nachricht hinterlassen: " + comment + "</p>" : ""}
                  <p>Bitte stellt uns die überarbeiteten Dateien innerhalb der vereinbarten Lieferzeit zur Verfügung und beachtet, dass bei Nichteinhaltung der Lieferzeit gemäß Vertrag die Gage für den Auftrag um 50% reduziert werden kann.</p>`;
        }
      } else {
        to = CONSTANTS.SUPPORT_EMAILS.PRODUCTION;
      }
      let subject = `Achtung - Dateien abgelehnt - ${address} - ${
        suborder.Desc
      } - ${product} - IMOGENT (ID: ${shortener(<string>suborder?.id, 6)})`;

      if (environment.env !== "prod") {
        to = CONSTANTS.SUPPORT_EMAILS.DEVELOP;
        subject = "Test E-Mail: " + subject;
      }

      const emailObj = {
        to: to,
        subject: subject,
        body: body,
      };

      this.ses.sendEmail(emailObj);
    } catch (e) {
      console.error(this.logService + "Error found while trying to send email.", e);
    }
  }

  /**
   * Updates status infos of pending orders from suborder with suborderId
   * @param suborderId The suborderId of the suborders whichs pending suborder will be updated.
   * @param adminCommentsForPendingSuborder Optional admin comments that will be added to pending suborders
   */
  async updatePendingSuborder(
    suborderId: string,
    adminCommentsForPendingSuborder?: string,
    debugLogs?: string[]
  ): Promise<string[]> {
    const updateSuborderPromises: Promise<void>[] = [];
    const subOrder = await this.sos.getSuborder(suborderId);
    let pendingSubOrdersList = [];
    try {
      debugLogs.push("Fetching dependent orders");
      pendingSubOrdersList = await this.getPendingSuborders(suborderId, subOrder.orderId);
      debugLogs.push("Dependent orders: " + pendingSubOrdersList.map((s) => s.id).join(", "));
      pendingSubOrdersList.forEach((curSubOrder: SubOrder) => {
        const updateSuborderPromise = new Promise<void>(async (resolve) => {
          debugLogs.push("Processing suborder: " + curSubOrder.id);
          // create order on esoft at this point if esoft api is enabled
          let adminActionRequired: boolean;
          let serviceProviderActionRequired: boolean;
          const dateNow = new Date();
          if (adminCommentsForPendingSuborder) {
            await this.sos.patchSuborder(curSubOrder.id, {
              adminComments: adminCommentsForPendingSuborder,
            });
            curSubOrder.adminComments = adminCommentsForPendingSuborder;
          }
          debugLogs.push("Sending order to Esoft: " + curSubOrder.id);
          await this.esClient.createOrder(curSubOrder);
          debugLogs.push("Order sent to Esoft: " + curSubOrder.id);
          console.log("order creation on esoft handled");
          const statusDates = curSubOrder.statusDates;
          statusDates.processing = dateNow;

          // suborders with an active auto pilot, dont require admin action
          if (curSubOrder.hasAutoPilot) {
            adminActionRequired = false;

            // adding 24h to the processing time in order to get the estimated delivery date
            statusDates.feedbacks[curSubOrder.feedbackNumber - 1].estimatedDelivery = addBusinessDaysToDate(
              statusDates.processing,
              1
            );
          } else {
            adminActionRequired = curSubOrder.feedbacks_included > 0;
          }
          serviceProviderActionRequired = !adminActionRequired;

          await this.updateStatusDates(
            curSubOrder,
            statusDates,
            adminActionRequired,
            false,
            false,
            serviceProviderActionRequired,
            {
              status: OrderStatusEnum.Processing,
              hasAutoPilot: true, // Once the parent order is closed, the dependent Esoft order can be set on autopilot
            },
            false
          );
          resolve();
        });
        updateSuborderPromises.push(updateSuborderPromise);
      });

      this.auth.showLoader.emit(true);
      await Promise.all(updateSuborderPromises);
      this.auth.showLoader.emit(false);
    } catch (error) {
      console.error(this.logService + "Error found while trying to update pending suborder:", suborderId, error);
    }

    return pendingSubOrdersList;
  }

  /**
   * Returns a promise with the pending suborders of the defined suborderId in the given order
   * @param suborderId id of the suborder
   * @param orderId id of the order
   */
  async getPendingSuborders(suborderId: string, orderId: string): Promise<SubOrder[]> {
    const suborders: SubOrder[] = await this.sos.getSubordersOfOrder(orderId);
    return suborders.filter(
      (so: SubOrder) => so.pendingOnSuborderId === suborderId && so.status === OrderStatusEnum.Pending
    );
    // TODO: Check the implementation of this function:
    // NOTE: The old implementation below seems to work very unreliable. Skip 1 skips the first submitted value which in fact often is the correct value.
    // NOTE: Then, the second value often is wrong and the suborder at esoft is not created because no fitting pending suborder is found.
    // NOTE: Nevertheless, I decided to keep the old code since also worked in a lot of cases.
    /*    return new Promise<SubOrder[]>((resolve) => {
      this.ws.allSuborders.pipe(skip(1), take(1)).subscribe((subOrdersList) => {
        resolve(
          subOrdersList.filter(
            (item) => item.pendingOnSuborderId === suborderId && item.status === OrderStatusEnum.Pending
          )
        );
      });
      this.ws.getSuborders(orderId);
    });*/
  }

  private async handleOffenblendeOrderCreation(orderId: string) {
    const suborders = await this.sos.getSubordersOfOrder(orderId);
    const offenblendeCandidates = suborders.filter(
      (suborder) =>
        this.offenblendeConfig.offenblendeProducts.includes(suborder.packageNum.split("|").pop()) &&
        this.offenblendeConfig.offenblendeUIDs.includes(suborder.assignedTo)
    );

    const offenblendeSuborderIds = offenblendeCandidates
      .filter((suborder) => !suborder.pendingOnSuborderId)
      .map((suborder) => suborder.id);
    await this.offenblendeService.createOrder(offenblendeSuborderIds);
    const suborderUpdatePromises = [];
    offenblendeSuborderIds.forEach((suborderId) => {
      const suborder = suborders.find((so) => so.id === suborderId);
      const statusDates = suborder.statusDates;
      statusDates[OrderStatusEnum.Processing] = new Date();
      suborderUpdatePromises.push(
        this.sos.patchSuborder(suborderId, {
          status: OrderStatusEnum.Processing,
          adminActionRequired: false,
          serviceProviderActionRequired: true,
          statusDates: statusDates,
        })
      );
    });

    await Promise.all(suborderUpdatePromises);
  }

  /**
   * Cancel all the dependent suborders when a parent suborder is cancelled
   * @param subOrderId - parent suborder id
   * @param orderId - main order id
   */
  async cancelDependingSubOrders(subOrderId, orderId) {
    const pendingSubOrdersList = await this.getPendingSuborders(subOrderId, orderId);
    const suborderUpdatePromises = [];
    pendingSubOrdersList.forEach((dependentSubOrder) => {
      const statusDates = dependentSubOrder.statusDates;
      statusDates[OrderStatusEnum.Canceled] = new Date();
      suborderUpdatePromises.push(
        this.sos.patchSuborder(dependentSubOrder.id, {
          status: OrderStatusEnum.Canceled,
          adminActionRequired: false,
          serviceProviderActionRequired: false,
          statusDates,
        })
      );
    });
    await Promise.all(suborderUpdatePromises);
  }
}
